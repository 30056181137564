@use "../../../styles/variables.module" as *;
@use "../../../styles/mixins";

.pageBody {
  position: relative;
}

.noContent {
  text-align: center;
  margin-top: 100px;
  font-size: 2rem;

  button {
    background: $admin-blue-dark;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
}
