// HOW GLOBAL AND DYNAMIC STYLES ARE HANDLED
// All styles are initially defined with default values in :root element with -- prefix.
// On initial load, StyleStore pulls styles from database and defines them in the :root element using css-vars-ponyfill Pollyfill
// Components charged with customization (i.e. ColorPicker) communicate with StyleLoader to send requests to API for updating styles
// Once color variables are changed, DOM automatically updates styles without causing reload or re-render

// this is the font that should go on block colored objects
.blocksFontColor {
  color: var(--blocksTextColor) !important;
  font-family: var(--fontFamily) !important;

  &:active, &:hover, &:focus {
    color: var(--blocksTextColor) !important;
  }
}

// this is the font that should go main text area
.themeFontColor {
  color: var(--primaryFontColor) !important;
  font-family: var(--fontFamily) !important;

  &:active {
    color: var(--primaryFontColor) !important;
  }
}

.blocksBackgroundColor {
  background-color: var(--blocks);

  color: var(--blocksTextColor) !important;
  font-family: var(--fontFamily) !important;

  &:active, &:hover, &:focus {
    color: var(--blocksTextColor) !important;
  }
}

.secondaryNavBackgroundColor {
  background-color: var(--secondaryNavBackground);

  color: var(--secondaryNavBackgroundTextColor) !important;
  font-family: var(--fontFamily) !important;

  &:active, &:hover, &:focus {
    color: var(--secondaryNavBackgroundTextColor) !important;
  }
}

.themeBackgroundColor {
  background-color: #fff;

  color: var(--primaryFontColor) !important;
  font-family: var(--fontFamily) !important;

  &:active {
    color: var(--primaryFontColor) !important;
  }
}

.themeFontFamily {
  font-family: var(--fontFamily);
}

$break-point-xs: 1px;
$break-point-sm: 608px;
$break-point-md: 912px;
$break-point-lg: 1216px;

$background-color: #fff;
$background-color2: rgb(248, 248, 248);
//$theme-color-blue: #094a7e;
// original database blue: #00699e
$theme-color-blue: #6C819B;
$theme-color-green: #A5BF86;

$admin-blue-dark: #6C819B;
$admin-blue-light: #8CA8CB;
$admin-green-dark: #A5BF86;
$admin-green-light: #FAFAF7;

$schoolfeed-light-blue: #4DADEC;

$clever-orange: #FF9800;
$clever-lgray: #F2F4F7;
$clever-gray: #E0E1E2;
$clever-dgray: #5C5D5D;

//$admin-accent: var(--accentColor);

$twitter-color: #43bfff;
$facebook-color: #094d80;
$instagram-color: #1f86d5;
$linkedin-color: #4176b3;
$office365-color: #ea3e23;
$google-color: #4285f4;
$pinterest-color: #c8232c;
$youtube-color: #ff0000;
$vimeo-color: #1ab7ea;
$yelp-color: #d32323;
$tripadvisor-color: #078171;
$rss-color: #f26522;
$school-passport-color: #4a4de5;

$call-to-action-color: #feb626;

// this must be a specific number across all devices because of the calculations we do in Javascript (that rely on this
// number).
$primary-nav-height: 82px;
$floating-blocks-header-margin: 17px;
$navbar-container-height: 52px;

$hero-logo-top: 8rem;
$hero-logo-height: 10rem;
$hero-title-text-top: 15rem;
$hero-header-text-top: $hero-title-text-top + 5rem;
$hero-view-homepage-button-top: $hero-header-text-top + 5rem;
$terraced-page-hero-height: 86vh;

$lightgray: #d6d6d6;
$faded-gray: #8e8e8d;
$dark-gray: #292929;
$black: #333333; //rgb(51,51,51) used in terrace titles and other important text fields
$gray: #595959; //rgb(89,89,89) used in most description/body/non title text fields

$one-block-width: 500px;
$one-block-padding: 40px;
$one-block-height: 400px;

$one-event-width: 600px;

$mobile-list-item-height: 50px;
$mobile-list-margin-top: 17px;
$mobile-list-margin-bottom: 14px;
$mobile-list-margin-right: 3px;
$mobile-list-margin-left: 3px;

$mobile-content-container-padding: $mobile-list-item-height + $mobile-list-margin-top + $mobile-list-margin-bottom;

/* Packery Grid */
$column-width: 304px;
$column-width-small: 140px;

$row-height: $column-width * 0.5;
$row-height-small: $column-width-small * 0.5;

$search-row-height: $row-height + 23px;
$search-row-height-small: $row-height-small + 23px;

$modal-min-height: 300px;

$block-margin-horizontal: 14px;
$block-margin-vertical: 14px;
$block-border-radius: 6px;

$border-color: #ccc;

$error-red: #D82E55;
$box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

/* Terrace */
$terrace-outer-margin: 5vw;
$terrace-title-font-size: 32px;
$terrace-title-font-size-mobile: 26px;
$terrace-title-font-weight: 700;
$terrace-subheading-font-size: 24px;
$terrace-subheading-font-size-mobile: 20px;
$terrace-subheading-font-weight: 650;
$terrace-subheading-font-weight-two: 600;
$terrace-body-font-size: 20px;
$terrace-body-font-size-mobile: 18px;

$static-page-margin: 0 $terrace-outer-margin 5rem $terrace-outer-margin;

/*
This value was calculated to ensure ADA compliance by setting a solid black background image and checking
contrast of background color at this calculated page width against the $black variable foreground color which is
the color of terrace titles. If $terrace-outer-margin or linear-gradient background were to change, this max-width
value needs to be manually recalculated.
*/
$terrace-title-max-width: calc(50vw - $terrace-outer-margin);

:export {
  break-point-xs: $break-point-xs;
  break-point-sm: $break-point-sm;
  break-point-md: $break-point-md;
  break-point-lg: $break-point-lg;
  background-color: $background-color;

  theme-color-blue: $theme-color-blue;
  theme-color-green: $theme-color-green;

  admin-blue-dark: $admin-blue-dark;
  admin-blue-light: $admin-blue-light;
  admin-green-dark: $admin-green-dark;
  admin-green-light: $admin-green-light;

  schoolfeed-light-blue: $schoolfeed-light-blue;

  clever-orange: $clever-orange;
  clever-lgray: $clever-lgray;
  clever-gray: $clever-gray;
  clever-dgray: $clever-dgray;

  twitter-color: $twitter-color;
  facebook-color: $facebook-color;
  instagram-color: $instagram-color;
  linkedin-color: $linkedin-color;
  office365-color: $office365-color;
  google-color: $google-color;
  pinterest-color: $pinterest-color;
  youtube-color: $youtube-color;
  vimeo-color: $vimeo-color;
  yelp-color: $yelp-color;
  tripadvisor-color: $tripadvisor-color;
  rss-color: $rss-color;

  call-to-action-color: $call-to-action-color;

  primary-nav-height: $primary-nav-height;
  floating-blocks-header-margin: $floating-blocks-header-margin;
  navbar-container-height: $navbar-container-height;

  hero-logo-top: $hero-logo-top;
  hero-logo-height: $hero-logo-height;
  hero-title-text-top: $hero-title-text-top;
  hero-header-text-top: $hero-header-text-top;
  hero-view-homepage-button-top: $hero-view-homepage-button-top;
  terraced-page-hero-height: $terraced-page-hero-height;

  lightgray: $lightgray;
  faded-gray: $faded-gray;
  dark-gray: $dark-gray;
  black: $black;
  gray: $gray;

  one-block-width: $one-block-width;
  one-block-padding: $one-block-padding;
  one-block-height: $one-block-height;

  one-event-width: $one-event-width;

  mobile-list-item-height: $mobile-list-item-height;
  mobile-list-margin-top: $mobile-list-margin-top;
  mobile-list-margin-bottom: $mobile-list-margin-bottom;
  mobile-list-margin-right: $mobile-list-margin-right;
  mobile-list-margin-left: $mobile-list-margin-left;

  mobile-content-container-padding: $mobile-content-container-padding;

  /* Packery Grid */
  column-width: $column-width;
  column-width-small: $column-width-small;

  row-height: $row-height;
  row-height-small: $row-height-small;

  search-row-height: $search-row-height;
  search-row-height-small: $search-row-height-small;

  block-margin-horizontal: $block-margin-horizontal;
  block-margin-vertical: $block-margin-vertical;
  block-border-radius: $block-border-radius;

  border-color: $border-color;

  error-red: $error-red;
  box-shadow: $box-shadow;

  terrace-outer-margin: $terrace-outer-margin;
}
